import { org } from "./environment.constant";
import { IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
  production: true,
  BUILD_ENV: "production",
  RELEASE: "0.17.4#a2ee64c",
  API_V0: `https://${org}point4more.com`,
  API_V1: "https://service.point4more.com",
  API_V2: "https://api.convercus.io",
  API_MOUNTING: "https://api.convercus.io",
  API_SOCKET: "https://socket.api.convercus.io",
  SELFSERVICE: `https://admin.convercus.io/${org}`,
};
